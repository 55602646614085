import React from 'react'
import styled, { css } from 'styled-components'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import Button from 'components/Button'
import { sizes } from 'styles'

const StyledNavigationButton = styled(Button)`
  padding: 8px 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fff;
      color: #a2a2a2;
      font-weight: normal;
      box-shadow: none;
    `}

  @media ${sizes.tabletPortrait} {
    :first-child {
      padding-left: 10px;
    }

    :last-child {
      padding-right: 10px;
    }
  }
`

const MobileText = styled.span`
  @media ${sizes.tabletPortrait} {
    display: none;
  }
`

const DesktopText = styled.span`
  display: none;
  @media ${sizes.tabletPortrait} {
    display: inline;
  }
`

const IconContainer = styled.div`
  width: 25px;
  height: 25px;
  margin: 0 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #a2a2a2;
  opacity: 1;
  display: none;

  ${({ disabled }) =>
    !disabled &&
    css`
      color: ${({ theme }) => theme.color.primary};
      background-color: ${({ theme }) => theme.color.white};
      opacity: 0.3;
    `}

  @media ${sizes.tabletPortrait} {
    display: flex;
  }
`

const NavigationButton = ({ disabled, onClick, isNext, className }) => {
  return (
    <StyledNavigationButton
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {isNext ? (
        <>
          <MobileText>Next</MobileText>
          <DesktopText>Next page</DesktopText>
          <IconContainer disabled={disabled}>
            <FaArrowRight />
          </IconContainer>
        </>
      ) : (
        <>
          <IconContainer disabled={disabled}>
            <FaArrowLeft />
          </IconContainer>
          <MobileText>Back</MobileText>
          <DesktopText>Previous page</DesktopText>
        </>
      )}
    </StyledNavigationButton>
  )
}

export default NavigationButton
