import React from 'react'
import styled, { css } from 'styled-components'
import TransitionLink from 'components/TransitionLink'

const StyledPageType = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.tertiary};
`

const Type = styled(TransitionLink)`
  position: relative;
  padding-right: 120px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  text-decoration: none;

  ${({ $showLine }) =>
    $showLine &&
    css`
      ::after {
        content: ' ';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 100px;
        height: 1px;
        background-color: #bebebe;
      }
    `}
`

const Identifier = styled.div`
  font-size: 20px;
  font-family: ${({ theme }) => theme.font.headings};
  font-style: italic;
`

const PageType = ({ type, identifier, linkTo, className }) => (
  <StyledPageType className={className}>
    <Type to={linkTo} $showLine={identifier}>
      {type}
    </Type>
    <Identifier>{identifier}</Identifier>
  </StyledPageType>
)

export default PageType
