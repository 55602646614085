import React from 'react'
import styled from 'styled-components'
import RichTextRenderer from 'components/RichTextRenderer'
import NavigationButton from 'components/NavigationButton'

const BookSectionTitleContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SectionTitle = styled.h3`
  margin: 0;
  font-size: 24px;
  font-weight: bold;

  :not(:first-of-type) {
    margin-top: 40px;
  }
`

const SectionContainer = styled.div`
  padding: 20px;

  > :last-child {
    margin-bottom: 0;
  }

  > :first-child {
    margin-top: 0;
  }
`

const SectionNavigation = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a2a2a2;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

const FullscreenBookView = ({
  openSectionIndex,
  setOpenSectionIndex,
  sections,
}) => {
  return (
    <>
      <BookSectionTitleContainer>
        <SectionTitle>{sections[openSectionIndex].title}</SectionTitle>
      </BookSectionTitleContainer>
      <SectionContainer>
        <RichTextRenderer richText={sections[openSectionIndex].content.json} />
      </SectionContainer>
      <SectionNavigation>
        <div>{`Page ${openSectionIndex + 1} of ${sections.length}`}</div>
        <ButtonsContainer>
          <NavigationButton
            disabled={openSectionIndex <= 0}
            onClick={() => setOpenSectionIndex(openSectionIndex - 1)}
          />
          <NavigationButton
            isNext
            disabled={openSectionIndex >= sections.length - 1}
            onClick={() => setOpenSectionIndex(openSectionIndex + 1)}
          />
        </ButtonsContainer>
      </SectionNavigation>
    </>
  )
}

export default FullscreenBookView
