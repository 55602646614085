import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { sizes } from 'styles'

const StyledScrollPercentage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transform: ${({ isGlobalNavOpen }) =>
    isGlobalNavOpen ? 'translateY(115px)' : 'translateY(60px)'};
  transition: transform 200ms;
  width: ${({ percentage }) => `${percentage}%`};
  height: 5px;
  background-color: ${({ theme }) => theme.color.tertiary};
  z-index: ${({ isMobileProductNavOpen }) => (isMobileProductNavOpen ? 0 : 1)};

  @media ${sizes.tabletLandscape} {
    transform: ${({ isGlobalNavOpen }) =>
      isGlobalNavOpen ? 'translateY(120px)' : 'translateY(70px)'};
    z-index: 2;
  }
`

const ScrollPercentage = ({ contentLength }) => {
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [isGlobalNavOpen, isMobileProductNavOpen] = useSelector((state) => [
    state.isGlobalNavOpen,
    state.isMobileProductNavOpen,
  ])

  useLayoutEffect(() => {
    const handleScroll = () => {
      const currentScrollPercentage =
        ((window.scrollY + window.innerHeight) / contentLength) * 100
      setScrollPercentage(
        currentScrollPercentage > 100 ? 100 : currentScrollPercentage
      )
    }
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [contentLength])

  return (
    <StyledScrollPercentage
      percentage={scrollPercentage}
      isGlobalNavOpen={isGlobalNavOpen}
      isMobileProductNavOpen={isMobileProductNavOpen}
    />
  )
}

export default ScrollPercentage
