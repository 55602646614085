import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import romanize from 'romanize'
import screenfull from 'screenfull'
import Container from 'components/Container'
import Seo from 'components/Seo'
import RichTextRenderer from 'components/RichTextRenderer'
import PostLayout from 'components/PostLayout'
import PageTitle from 'components/PageTitle'
import ChapterNavigation from 'components/ChapterNavigation'
import Share from 'components/Share'
import SubscribeBox from 'components/SubscribeBox'
import BookView from 'components/BookView'
import FullscreenBookView from 'components/FullscreenBookView'
import Preview from 'components/Preview'
import Button from 'components/Button'
import PageType from 'components/PageType'
import ScrollPercentage from 'components/ScrollPercentage'
import Blog from 'images/icons/blog.svg'
import BlogInactive from 'images/icons/blog_inactive.svg'
import Book from 'images/icons/book.svg'
import BookInactive from 'images/icons/book_inactive.svg'
import { setIsBlogView } from 'state/actions'
import { sizes } from 'styles'

const ViewToggleButton = styled.button`
  height: 31px;
  padding: 5px 10px;
  margin-bottom: 30px;
  border: 1px solid #a2a2a2;
  border-radius: 5px;
  display: none;
  flex-direction: row;
  font-size: 16px;
  background-color: #f2f2f2;
  color: #a2a2a2;
  cursor: pointer;

  @media ${sizes.tabletLandscape} {
    display: inline-flex;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    border: 1px solid #bbbbe6;
    background-color: ${theme.color.backgrounds};
    color: ${theme.color.tertiary};
  `}

  > img {
    box-sizing: border-box;
    width: 27px;
    height: 100%;
    padding-right: 5px;
  }

  :focus {
    outline: none;
  }

  :first-of-type {
    margin-right: 10px;
  }
`

const ReadingTime = styled.div`
  opacity: 0.3;
  margin-bottom: 20px;
`

const StyledPageTitle = styled(PageTitle)`
  position: relative;
  margin: 20px 0;
  padding-top: 40px;

  ::before {
    content: ${({ topicIndex }) =>
      `'${topicIndex > 9 ? topicIndex + 1 : `0${topicIndex + 1}`}'`};
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    line-height: 1;
    color: ${({ theme }) => theme.color.tertiary};
    font-weight: normal;
    font-style: italic;
    font-family: ${({ theme }) => theme.font.headings};
  }
`

const Highlight = styled.p`
  @media ${sizes.tabletLandscape} {
    max-width: 80%;
  }
`

const SectionTitle = styled.h3`
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bold;

  :not(:first-of-type) {
    margin-top: 40px;
  }
`

const StyledPreview = styled(Preview)`
  @media ${sizes.desktop} {
    max-width: 80%;
  }
`

const NextTopic = styled.div`
  padding-top: 40px;
  margin-top: 60px;
  border-top: 1px solid #e3e3e3;
`

const FullScreenView = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  overflow: scroll;
  background-color: #fff;

  ::backdrop {
    background-color: #fff;
  }
`

const FullScreenContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 100px;
`

const ExitFullScreenButton = styled(Button)`
  position: fixed;
  right: 20px;
  top: 20px;
`

const StyledChapterNavigation = styled(ChapterNavigation)`
  display: none;
  @media ${sizes.tabletLandscape} {
    display: block;
  }
`

const StyledSubscribeBox = styled(SubscribeBox)`
  width: 100%;
  max-width: 400px;
  margin-top: 60px;
`

const Topic = ({ data, location, pageContext }) => {
  const dispatch = useDispatch()
  const fullscreenRef = useRef()
  const contentLengthRef = useRef()
  const isBlogView = useSelector((state) => state.isBlogView)
  const [openSectionIndex, setOpenSectionIndex] = useState(0)
  const [isFullScreen, setIsFullScreen] = useState(0)
  const [contentLength, setContentLength] = useState(0)
  const { contentfulTopic, allContentfulLibraryPage } = data
  const { id, title, readingTime, highlight, sections, slug } = contentfulTopic
  const chapters = allContentfulLibraryPage.edges[0].node.chapters
  const openChapter = chapters.find((chapter) =>
    chapter.topics.find((topic) => topic.slug === slug)
  )
  const nextChapter = chapters[chapters.indexOf(openChapter) + 1]
  const topicsArr = openChapter.topics
  const topicIndex = topicsArr.map((x) => x.id).indexOf(id)

  useEffect(() => {
    const sectionIndex = location.search.split('?section=')[1]
    if (
      !sectionIndex ||
      Number(sectionIndex) >= sections.length ||
      Number(sectionIndex) <= 0
    ) {
      return
    }
    setOpenSectionIndex(Number(sectionIndex - 1) || 0)
  }, [location.search, sections.length])

  useEffect(() => {
    if (isBlogView) {
      navigate(location.pathname, { replace: true })
    } else {
      navigate(`?section=${openSectionIndex + 1}`, { replace: true })
    }
  }, [openSectionIndex, isBlogView, location.pathname])

  useLayoutEffect(() => {
    const fullHeight =
      contentLengthRef.current.clientHeight + contentLengthRef.current.offsetTop
    setContentLength(fullHeight)
  }, [contentLengthRef, isBlogView])

  const handleFullScreen = () => {
    if (screenfull.isEnabled && fullscreenRef.current) {
      screenfull.on('change', () => {
        setIsFullScreen(screenfull.isFullscreen)
      })
      screenfull.request(fullscreenRef.current)
    }
  }

  const getBlogView = () => (
    <>
      {sections.map((section, i) => (
        <React.Fragment key={i}>
          <SectionTitle>{section.title}</SectionTitle>
          <RichTextRenderer richText={section.content.json} />
        </React.Fragment>
      ))}
    </>
  )

  const showNextTopicPreview = () => {
    if (isBlogView) {
      return topicIndex < topicsArr.length - 1
    }
    return (
      openSectionIndex === sections.length - 1 &&
      topicIndex < topicsArr.length - 1
    )
  }

  const showNextChapterPreview = () => {
    if (isBlogView) {
      return topicIndex === topicsArr.length - 1 && nextChapter
    }
    return (
      openSectionIndex === sections.length - 1 &&
      topicIndex === topicsArr.length - 1 &&
      nextChapter
    )
  }

  const getContent = () => (
    <>
      <div ref={contentLengthRef}>
        <PageType
          type="chapter"
          identifier={romanize(chapters.indexOf(openChapter) + 1)}
          linkTo={`/chapter/${chapters[chapters.indexOf(openChapter)].slug}`}
        />
        <StyledPageTitle topicIndex={topicIndex}>{title}</StyledPageTitle>
        <Highlight>{highlight?.highlight}</Highlight>
        <ReadingTime>{`${readingTime} read`}</ReadingTime>
        <ViewToggleButton
          isActive={!isBlogView}
          onClick={() => dispatch(setIsBlogView(false))}
        >
          <img src={!isBlogView ? Book : BookInactive} alt="book" />
          Book view
        </ViewToggleButton>
        <ViewToggleButton
          isActive={isBlogView}
          onClick={() => dispatch(setIsBlogView(true))}
        >
          <img src={isBlogView ? Blog : BlogInactive} alt="blog" />
          Blog view
        </ViewToggleButton>
        <FullScreenView ref={fullscreenRef} hide={!isFullScreen}>
          <FullScreenContainer>
            <ExitFullScreenButton onClick={() => screenfull.exit()}>
              Exit fullscreen
            </ExitFullScreenButton>
            <FullscreenBookView
              openSectionIndex={openSectionIndex}
              setOpenSectionIndex={setOpenSectionIndex}
              sections={sections}
            />
          </FullScreenContainer>
        </FullScreenView>
        {isBlogView ? (
          getBlogView()
        ) : (
          <BookView
            openSectionIndex={openSectionIndex}
            setOpenSectionIndex={setOpenSectionIndex}
            sections={sections}
            onFullScreenRequested={handleFullScreen}
          />
        )}
        {showNextTopicPreview() && (
          <NextTopic>
            <p>Continue to the next topic</p>
            <StyledPreview
              title={topicsArr[topicIndex + 1].title}
              highlight={topicsArr[topicIndex + 1].highlight.highlight}
              index={topicIndex + 1}
              link={pageContext.nextPageLink}
            />
          </NextTopic>
        )}
        {showNextChapterPreview() && (
          <NextTopic>
            <p>Continue to the next chapter</p>
            <StyledPreview
              title={nextChapter.title}
              highlight={nextChapter.description.description}
              link={`/chapter/${nextChapter.slug}/`}
            />
          </NextTopic>
        )}
      </div>
      <StyledSubscribeBox />
    </>
  )

  const getAsideContent = () => (
    <>
      <StyledChapterNavigation location={location.pathname} />
      <Share
        shareTitle={title}
        shareLink={typeof window !== 'undefined' ? window.location : ''}
      />
    </>
  )

  return (
    <>
      {isBlogView && <ScrollPercentage contentLength={contentLength} />}
      <Container>
        <Seo
          title={title}
          image={{
            src:
              openChapter.podcastEpisodes &&
              openChapter.podcastEpisodes[0].image
                ? openChapter.podcastEpisodes[0].image.resize.src
                : undefined,
            width: 1600,
            height: 900,
          }}
          description={
            highlight ? highlight.highlight.substr(0, 157) + '...' : undefined
          }
        />
        <PostLayout
          mainContent={getContent()}
          asideContent={getAsideContent()}
        />
      </Container>
    </>
  )
}

export const topicQuery = graphql`
  query topicQuery($slug: String!) {
    contentfulTopic(slug: { eq: $slug }) {
      id
      slug
      title
      readingTime
      highlight {
        highlight
      }
      sections {
        title
        content {
          json
        }
      }
    }
    allContentfulLibraryPage(limit: 1) {
      edges {
        node {
          chapters {
            title
            slug
            description {
              description
            }
            topics {
              id
              title
              highlight {
                highlight
              }
              slug
            }
            podcastEpisodes {
              image {
                resize(width: 1600, height: 900) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Topic
