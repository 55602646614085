import React from 'react'
import styled from 'styled-components'
import NavigationButton from 'components/NavigationButton'
import TransitionLink from 'components/TransitionLink'

const StyledPreview = styled.div`
  padding: 20px;
  border: 1px solid #bbbbe6;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.backgrounds};

  > h3 {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 34px;
  }

  > p {
    margin: 0;
  }
`

const Index = styled.div`
  color: ${({ theme }) => theme.color.tertiary};
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.headings};
  font-style: italic;
`

const NextLink = styled(TransitionLink)`
  margin-top: 20px;
  align-self: flex-end;
`

const Preview = ({ index, title, highlight, link, className }) => {
  let indexToDisplay = index + 1 <= 9 ? `0${index + 1}` : index + 1
  return (
    <StyledPreview className={className}>
      {index && <Index>{indexToDisplay}</Index>}
      <h3>{title}</h3>
      <p>{highlight}</p>
      <NextLink to={link}>
        <NavigationButton isNext>Next page</NavigationButton>
      </NextLink>
    </StyledPreview>
  )
}

export default Preview
