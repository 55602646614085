import React from 'react'
import styled from 'styled-components'
import RichTextRenderer from 'components/RichTextRenderer'
import NavigationButton from 'components/NavigationButton'
import FullScreenIcon from 'images/icons/fullscreen.svg'
import { sizes } from 'styles'

const BookSectionTitleContainer = styled.div`
  padding: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #bbbbe6;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.white};
`

const SectionTitle = styled.h3`
  margin: 0;
  font-size: 24px;
  font-weight: bold;

  :not(:first-of-type) {
    margin-top: 40px;
  }
`

const SectionContainer = styled.div`
  padding: 20px;
  border: 1px solid #bbbbe6;
  background-color: ${({ theme }) => theme.color.backgrounds};

  > :last-child {
    margin-bottom: 0;
  }

  > :first-child {
    margin-top: 0;
  }
`

const SectionNavigation = styled.div`
  padding: 15px 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #bbbbe6;
  border-top: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a2a2a2;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 8px;
  }
`

const FullScreen = styled.img`
  display: none;
  cursor: pointer;
  transform: scale3d(1, 1, 1);
  transition: transform 200ms;

  :hover {
    transform: scale3d(1.2, 1.2, 1);
  }

  @media ${sizes.smallDesktop} {
    display: inline;
  }
`

const BookView = ({
  openSectionIndex,
  setOpenSectionIndex,
  onFullScreenRequested,
  sections,
}) => {
  return (
    <>
      <BookSectionTitleContainer>
        <SectionTitle>{sections[openSectionIndex].title}</SectionTitle>
        <FullScreen src={FullScreenIcon} onClick={onFullScreenRequested} />
      </BookSectionTitleContainer>
      <SectionContainer>
        <RichTextRenderer richText={sections[openSectionIndex].content.json} />
      </SectionContainer>
      <SectionNavigation>
        <div>{`Page ${openSectionIndex + 1} of ${sections.length}`}</div>
        <ButtonsContainer>
          <NavigationButton
            disabled={openSectionIndex <= 0}
            onClick={() => setOpenSectionIndex(openSectionIndex - 1)}
          />
          <NavigationButton
            isNext
            disabled={openSectionIndex >= sections.length - 1}
            onClick={() => setOpenSectionIndex(openSectionIndex + 1)}
          />
        </ButtonsContainer>
      </SectionNavigation>
    </>
  )
}

export default BookView
